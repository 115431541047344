$(() => {
  if (!window.gon) window.gon = { hashtags: [] };

  $(".hashtaggable").textcomplete(
    [
      {
        // html
        hashtags: gon.hashtags || [],
        match: /\B#(\w*)$/,
        search(term, callback) {
          callback(
            $.map(this.hashtags, (hashtag) =>
              hashtag.indexOf(term) === 0 ? hashtag : null
            )
          );
        },
        index: 1,
        replace(hashtag) {
          return `#${hashtag} `;
        },
      },
    ],
    {
      appendTo: "body",
      onKeydown({ ctrlKey, keyCode }, { KEY_ENTER }) {
        if (ctrlKey && keyCode === 74) {
          // CTRL-J
          return KEY_ENTER;
        }
      },
    }
  );
});
