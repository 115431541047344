$(() => {
  const $searchPanel = $("#search-panel");
  const $searchInput = $("#search-input");
  const $searchInputToggle = $("#search-input-toggle");
  const $sortOption = $("#nav-filter-menu #sort_by");
  const $backButton = $(".back-button");
  const $pageContent = $(".rendered");
  const $stateSelect = $("#search-state");
  const $loading = $(".loading");
  const $searchIcon = $(".search-icon");
  const $searchTabs = $("ul#search-tabs");
  let defaultModel = "all";

  // read query params
  const urlParams = new URLSearchParams(window.location.search);
  const reps = urlParams.get("reps");
  const bills = urlParams.get("bills");
  const issues = urlParams.get("issues");

  let selectedModel = reps
    ? "reps"
    : bills
    ? "bills"
    : issues
    ? "issues"
    : defaultModel;

  $searchInput.val(gon.q);
  $searchInputToggle.val(gon.q);
  $sortOption.val(gon.sort);
  $searchPanel.show();

  if (gon.q) {
    $searchTabs.show();
  } else {
    $searchTabs.hide();
  }

  if (gon.sort) {
    $("#nav-filter-menu #sort a").each((index, el) => {
      if ($(el).data("sort") === gon.sort) {
        $(el).addClass("active");
      } else {
        $(el).removeClass("active");
      }
    });
  }

  if (window.location.pathname !== "/") {
    $backButton.show();
  } else {
    $backButton.hide();
  }

  // const setActiveTab = () => {
  //   $searchTabs.find("a").each((index, el) => {
  //     if ($(el).data("model") === selectedModel) {
  //       $(el).addClass("active");
  //     } else {
  //       $(el).removeClass("active");
  //     }
  //   });
  // };
  // setActiveTab();

  // $searchTabs.find("a").on("click", function () {
  //   selectedModel = $(this).data("model");
  //   setActiveTab();
  //   handleSearchQuery();
  // });

  $stateSelect.change(() => {
    gon.state_id = $stateSelect.val();
    handleSearchQuery();
  });

  const onSearch = (val) => {
    $searchIcon.hide();
    $loading.show();
    if (val.length === 0) {
      gon.q = val;
      $loading.hide();
      $searchIcon.show();
      $pageContent.show();
      $("#rendered").show();
      $("section#search").hide();
      window.location.href = "/";
    } else {
      if (val !== gon.q) {
        handleSearchQuery();
      } else {
        $loading.hide();
        $searchIcon.show();
      }
    }
  };

  $searchIcon.on("click", function () {
    onSearch($searchInput.val() || $searchInputToggle.val());
  });

  $searchInput.on("keyup", function (e) {
    $searchInputToggle.val($(this).val());
    if (e.which === 13 || e.keyCode === 13) {
      const val = $(this).val();
      onSearch(val);
      if (val.length === 0) {
        $searchIcon.show();
      } else if (val === gon.q) {
        $searchIcon.show();
      }
    }
  });

  $searchInputToggle.on("keyup", function (e) {
    $searchInput.val($(this).val());
    if (e.which === 13 || e.keyCode === 13) {
      const val = $(this).val();
      onSearch(val);
    }
  });

  function handleSearchQuery() {
    var query = null;
    if ($searchInput.val().length > 0) {
      query = $searchInput.val();
    } else if ($searchInputToggle.val().length > 0) {
      query = $searchInputToggle.val();
    }
    gon.q = query;
    search(query);
  }

  function search(query) {
    path = "/search";
    requestSearch(path, query);
  }

  function requestSearch(path, query) {
    window.location.href = `${path}?q=${encodeURIComponent(
      query
    )}&${selectedModel}=true&state_id=${gon.state_id || ""}&s=${
      gon.sort || ""
    }`;
  }

  function sortSearch(sort) {
    gon.sort = sort;
    requestSearch("/search", gon.q);
  }

  $("#nav-filter a").on("click", function (e) {
    e.preventDefault();
    $(this).tab("show");
    $($(this).data("target")).tab("show");
  });

  $("#nav-filter-menu #sort a").on("click", function (e) {
    e.preventDefault();
    $sortOption.val($(this).data("sort"));
    $(this).addClass("active").siblings().removeClass("active");
  });

  $("form#sort-form").on("submit", function (e) {
    e.preventDefault();
    sortSearch($sortOption.val());
  });
});
