$(() => {
  // Click reply to Comment

  $(document).on("click", ".comment .btn-comment-reply", function () {
    if (gon.role === "guest") {
      login();
    } else {
      $(".reply").hide();
      var $comment = $(`.comment#${$(this).data("comment-id")}`);
      $comment
        .find("> ul.children-comments > .reply")
        .show()
        .find("textarea")
        .focus();
    }
  });
});
