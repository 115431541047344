$(() => {
  const $form = $("#vote-form");
  $("#actions-hint").addClass("hide");
  $(".selected-vote-bg").addClass("show");
  $(".unselected-vote-bg").addClass("hide");
  const $yesSelected = $(".vote-yes .vote-icon-group .selected-vote-bg");
  const $yesUnSelected = $(".vote-yes .vote-icon-group .unselected-vote-bg");
  const $noSelected = $(".vote-no .vote-icon-group .selected-vote-bg");
  const $noUnSelected = $(".vote-no .vote-icon-group .unselected-vote-bg");
  const $unsureSelected = $(".vote-unsure .vote-icon-group .selected-vote-bg");
  const $unsureUnSelected = $(".vote-unsure .vote-icon-group .unselected-vote-bg");

  const switchVoteBtn = (stance) => {
    let $updateSelected, $updateUnselected, $oneNoSelectedHide, $oneNoSelectedShow, $twoNoSelectedHide, $twoNoSelectedShow = null;
    if (stance === "Yes") {
      $updateSelected = $yesSelected;
      $updateUnselected = $yesUnSelected;
      $oneNoSelectedHide = $noSelected;
      $oneNoSelectedShow = $noUnSelected;
      $twoNoSelectedHide = $unsureSelected;
      $twoNoSelectedShow = $unsureUnSelected;
    } else if (stance === "No") {
      $updateSelected = $noSelected;
      $updateUnselected = $noUnSelected;
      $oneNoSelectedHide = $yesSelected;
      $oneNoSelectedShow = $yesUnSelected;
      $twoNoSelectedHide = $unsureSelected;
      $twoNoSelectedShow = $unsureUnSelected;
    } else if (stance === "Unsure") {
      $updateSelected = $unsureSelected;
      $updateUnselected = $unsureUnSelected;
      $oneNoSelectedHide = $yesSelected;
      $oneNoSelectedShow = $yesUnSelected;
      $twoNoSelectedHide = $noSelected;
      $twoNoSelectedShow = $noUnSelected;
    }
    return [
      $updateSelected.removeClass("hide").addClass("show"),
      $updateUnselected.removeClass("show").addClass("hide"),
      $oneNoSelectedHide.removeClass("show").addClass("hide"),
      $oneNoSelectedShow.removeClass("hide").addClass("show"),
      $twoNoSelectedHide.removeClass("show").addClass("hide"),
      $twoNoSelectedShow.removeClass("hide").addClass("show")
    ];
  };

  $(".vote-yes").click(() => {
    submitVote("Yes");
    switchVoteBtn("Yes");
  });

  $(".vote-no").click(() => {
    submitVote("No");
    switchVoteBtn("No");
  });

  $(".vote-unsure").click(() => {
    submitVote("Unsure");
    switchVoteBtn("Unsure");
  });

  function submitVote(stance) {
    $("#vote_stance").val(stance);

    if (gon.verified) triggerCommentModal(stance);
    else triggerStanceModal(stance);
  }

  function triggerStanceModal(stance) {
    if (gon.role === "guest") {
      window.location.href = "/users/login";
    } else {
      $("#vote-modal").modal({
        backdrop: "static",
        keyboard: false,
      }).fadeIn();

      $(`#${stance}`).removeClass("hide");
    }
  }

  function triggerCommentModal(stance) {
    $("#actions-hint").removeClass("hide").addClass("show");
    $("#Yes, #No, #Unsure").addClass("d-none");
    $(`#${stance}`).removeClass("d-none").addClass("d-flex");
    $("#vote-comment").focus();
  }

  $(".vote-and-verify").click(() => {
    $("#get_verified").val(true);
    $form.submit();
  });

  $(".vote-and-skip-verify").click(() => {
    $form.submit();
  });

  function voteBill() {
    var submitted_data;
    if ($("#vote-comment").val()) {
      submitted_data = {
        vote: {
          stance: $("#vote_stance").val(),
          votable_type: $("#bill-votable-type").val(),
          votable_id: $("#bill-votable-id").val(),
          get_verified: $("#bill-votable-verified").val(),
          comment_attributes: {
            body: $("#vote-comment").val(),
            commentable_type: $("#vote-commentable-type").val(),
            commentable_id: $("#vote-commentable-id").val()
          }
        },
      }
    } else {
      submitted_data = {
        vote: {
          stance: $("#vote_stance").val(),
          votable_type: $("#bill-votable-type").val(),
          votable_id: $("#bill-votable-id").val(),
          get_verified: $("#bill-votable-verified").val()
        },
      }
    }
    $.ajax({
      url: "/votes",
      method: "post",
      data: submitted_data,
      success(data, textStatus, jqXHR) {
        if (textStatus === "success") {
          $("#success-submit-text").text($("#success-submit-text").text().replace("Thanks!", "You voted!"));
          $("#submission-success").removeClass("d-none").addClass("d-flex");
          localStorage.setItem("billVoted", true);
          location.reload(true);
        }
      },
    });
  };

  $(".submit-vote").click((e) => {
    e.preventDefault();
    voteBill();
  });

  $('#change-vote').bind('ajax:complete', function () {
    localStorage.setItem("voteDeleted", true);
    location.reload(true);
  });
});
if (localStorage.getItem("billVoted")) {
  $("#discussion-tab").tab("show");
  if (gon.bill_vote) {
    $("#discussion-tab-top").prepend($("#postVote"));
    setInterval(() => {
      $("#discussion-tab-top #postVote").fadeOut();
    }, 4000);
  }
  localStorage.removeItem("billVoted");
}
if (localStorage.getItem("voteDeleted")) {
  $("#vote-tab").tab("show");
  localStorage.removeItem("voteDeleted");
}
