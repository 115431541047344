function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      $(input).siblings('.img-preview').find('img').attr('src', e.target.result);
    };

    reader.readAsDataURL(input.files[0]);
  }
}

$(function(){
  $('.img-preview-input').change(function(){
    readURL(this);
  })
});