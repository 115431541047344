$(() => {
  $(document).on("click", ".follow", function (e) {
    e.stopPropagation();
    follow($(this));
  });

  $(document).on("click", ".unfollow", function (e) {
    e.stopPropagation();
    unfollow($(this));
  });

  function follow($followable) {
    $.ajax({
      url: "/follows",
      method: "post",
      data: {
        follow: {
          followable_type: $followable.data("followable-type"),
          followable_id: $followable.data("followable-id"),
        },
      },
      success(data, textStatus, jqXHR) {
        if (textStatus === "success") {
          $followable.html(
            '<span class="follow-link m-0 text-emerald-green text-xs">Unfollow</span>'
          );
          $followable.removeClass("follow").addClass("unfollow");
        }
      },
    });
  }

  function unfollow($followable) {
    $.ajax({
      url: "/follows/1",
      method: "delete",
      data: {
        follow: {
          followable_type: $followable.data("followable-type"),
          followable_id: $followable.data("followable-id"),
        },
      },
      success(data, textStatus, jqXHR) {
        if (textStatus === "nocontent") {
          $followable.html(
            '<span class="follow-link m-0 text-emerald-green text-xs">Follow</span>'
          );
          $followable.removeClass("unfollow").addClass("follow");
        }
      },
    });
  }
});
