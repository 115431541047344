$(() => {
    $("#rep-profile-claim-section #claim_rep_email").on(
    "change",
    (e) => {
      if (e.target.value !== "") {
        $("#voter-lookup-state-selection button").addClass("disabled");
      } else {
        $("#voter-lookup-state-selection button").removeClass("disabled");
      }
    }
  );
});