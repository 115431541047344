$(() => {
  $('button.btn[data-remote="true"]').click(function(e){
    const $target    = $(this), btnContent = $(this).html();

    $target.html('<i class="fa fa-spinner fa-spin">');
    $target.prop('disabled', true);

    $.getScript( constructUrl($target) )
      .done((script, textStatus) => {
        setTimeout(() => {
          renderContent($target);
          toggleDisabledState($target);
          renderNotification($target, btnContent);
        }, 2000);
      })
      .fail((jqxhr, settings, exception) => {
        console.log(jqxhr, settings, exception);
      });
  });

  // Render the original content or render the success content if optionally specified into the target dom element
  function renderContent($target, btnContent){
    const successContent = $target.data('success-content');
    if(successContent)
      $target.html(successContent);
    else
      $target.html(btnContent);
  }

  // Reenable the target if data-reenbale option specifies to
  function toggleDisabledState($target){
    $target.prop('disabled', !$target.data('reenable'));
  }

  // Render notification if data-notification is present
  function renderNotification($target){
    const notification = $target.data('notification');
    if(notification){
      $.notify(
        {
          message: notification .message
        },
        {
          type: notification .type
        }
      );
    }
  }

  function constructUrl($target) {
    let url = $target.data('url');

    if($target.data('url-param-fields')){
      url+="?";
      console.log($target.data('url-param-fields'));
      $.each($target.data('url-param-fields'), (i, param) => {
        if(i > 0)
          url+="&";
        url+=param.name;
        url+="=";
        url+=$(param.id).val();
      });

      return url;
    }

  }
});