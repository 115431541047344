$('input[name="verification[voter_record_id]"]').on("change", function () {
  $("#verify").removeClass("disabled").attr("disabled", false);
});

$(() => {
  $("form").trigger("reset");

  const $addressAutocomplete = $("#voter_lookup_address");
  let selected = false;

  $addressAutocomplete.bind("railsAutocomplete.select", (event, data) => {
    $("#address-id").val(data.item.id);
    selected = true;
  });

  var autocompleteTimeout;
  $addressAutocomplete.keyup(function (event) {
    clearTimeout(autocompleteTimeout);

    var searchTerm = $(this).val();

    if (searchTerm.length === 0) {
      $("#address-list-found").removeClass("d-flex flex-column");
      $("#address-list-found").addClass("hide");
    }

    if (searchTerm.length >= 3) {
      autocompleteTimeout = setTimeout(function () {
        $.ajax({
          url: "/autocomplete_address",
          type: "GET",
          data: {
            term: searchTerm,
            state_id: $("#voter_lookup_state_id").val(),
          },
          dataType: "json",
          success: function (data) {
            $("#voter-lookup-address-selection #address-id").val("");
            $("#voter-lookup-address-selection input[type=submit]").addClass(
              "disabled"
            );

            $("#address-list-found ul").empty();
            $("#address-list-found").removeClass("hide");
            $("#address-list-found").addClass("d-flex flex-column");

            if (data.length === 0) {
              $("#address-list-found #address-list").addClass("hide");
            } else {
              $("#address-list-found #address-list").removeClass("hide");
            }
            Array.from(data).forEach(function (value) {
              $("#address-list-found ul").append(
                `<li class="form-control rounded-1 text-nav-gray-lighter h-auto" data-id="${value["id"]}">` +
                  value["label"] +
                  "</li>"
              );
            });
            resetActiveAddresses();
          },
        });
      }, 1000);
    }
  });

  // Click on address
  $("#registered-voter-decision .form-control").each(function () {
    $(this).click(function () {
      $("#registered-voter-decision .form-control").removeClass("active");
      $(this).addClass("active");
    });
  });

  const resetActiveAddresses = () => {
    $("#address-list-found li").each(function () {
      $(this).click(function () {
        $("#address-list-found li").removeClass("active");
        $(this).addClass("active");
        $("#voter-lookup-address-selection #address-id").val(
          $(this).data("id")
        );
        $("#voter-lookup-address-selection input[type=submit]").removeClass(
          "disabled"
        );
      });
    });
  };

  // Registered voter decision (Step 1)
  $("#registered-voter-decision button").click(function () {
    const chosenOption = $("#registered-voter-decision .form-control.active");
    if (chosenOption.data("option") === "yes") {
      $("#registered-voter-decision").addClass("hide");
      $("#registered-voter-decision").removeClass("d-flex");
      $("#voter-lookup-form").removeClass("hide");
    } else {
      window.location.href = "/";
    }
  });

  // State selection (Step 2)
  $("#voter-lookup-state-selection #voter_lookup_state_id").on(
    "change",
    (e) => {
      const selectedOption = e.target.selectedOptions[0];
      if (e.target.value === "") {
        $("#voter-lookup-state-selection button").addClass("disabled");
        $("#voter-registration-link").text(`Voter Registration`);
        $("#voter-registration-link").attr("href", "#");
      } else {
        $("#voter-lookup-state-selection button").removeClass("disabled");
        $("#voter-registration-link").text(
          `${selectedOption.text} Voter Registration`
        );
        $("#voter-registration-link").attr(
          "href",
          selectedOption.attributes.link.value || "#"
        );
      }
    }
  );

  // Click Continue after state selection
  $("#voter-lookup-state-selection button").click(function (e) {
    e.preventDefault();
    $("#voter-lookup-address-selection").removeClass("hide");
    $("#voter-lookup-address-selection").addClass("d-flex");
    $("#voter-lookup-state-selection").removeClass("d-flex");
    $("#voter-lookup-state-selection").addClass("hide");
  });

  // Click on voter
  $("#found-voter-records .form-control").each(function () {
    $(this).click(function () {
      $("#found-voter-records .form-control").removeClass("active");
      $(this).addClass("active");
      $("#verify-voter").removeClass("disabled");
      $("#voter-record-id").val($(this).data("id"));
    });
  });

  // Input phone number
  $("#confirmation_phone_number").keyup((e) => {
    if ((e.target.value === "") || $('#confirmation_terms').is(':checked') === false) {
      $("#verify-phone").addClass("disabled");
    } else {
      $("#verify-phone").removeClass("disabled");
    }
  });

  $("#confirmation_terms").change((e) => {
    if(e.target.checked && $('#confirmation_phone_number').val() !== "") {
      $("#verify-phone").removeClass("disabled");
    } else {
      $("#verify-phone").addClass("disabled");
    }
  });

  // Input verification code
  $("#confirmation_verification_pin").keyup((e) => {
    if (e.target.value === "") {
      $("#verify-pin").addClass("disabled");
    } else {
      $("#verify-pin").removeClass("disabled");
    }
  });

  $("#photo-id-file-label").click(function () {
    $("#photo-id-file").click();
  });

  $("#photo-id-file").change(function () {
    const fileNames = Array.from($("#photo-id-file")[0].files).map(
      (file) => `<p class="m-0">${file.name}</p>`
    );
    $("#selected_filename").html(fileNames);
  });

  // Username
  $("#user_username").keyup(function (e) {
    if (e.target.value === "") {
      $("#sendUsernameButton").addClass("disabled");
    } else {
      $("#sendUsernameButton").removeClass("disabled");
      $('#availableUsernames').addClass('hide');
    }
  });

  $("#usernames-list li").each(function () {
    $(this).click(function () {
      $("#usernames-list li").removeClass("active");
      $(this).addClass("active");
      $("#user_username").val($(this).data("id"));
      $("#sendUsernameButton").removeClass("disabled");
    });
  });
});