import consumer from "./consumer";

if ($("meta[name='current-user']").length > 0) {
  var $notificationsContainer;

  $(function () {
    $notificationsContainer = $(".dropdown-notifications");
  });

  consumer.subscriptions.create("NotificationsChannel", {
    connected() {
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      consumer.notifications.appendNotifications(data.html);
    },

    appendNotifications() {
      if ($notificationsContainer.length) {
        $notificationsContainer.html(html);
      }
    },
  });
}
