$(function () {
  $(".comment-reply").on("click", function () {
    const $commentId = $(this).attr("data");
    $(`#discussionBody${$commentId}`).on("input", function () {
      if ($(this).val().length > 0) {
        $(`#discussionBox${$commentId}`).css("height", "auto");
        $(`#discussionBox${$commentId}`).css("flex-direction", "column");
        $(`#discussionBox${$commentId}`).css("align-items", "end");
        $(`#discussionBody${$commentId}`).addClass("expanded");
        $(`#discussionImageBox${$commentId}`).css("display", "flex");
      } else {
        $(`#discussionBox${$commentId}`).css("height", "2.5rem");
        $(`#discussionBox${$commentId}`).css("flex-direction", "row");
        $(`#discussionBox${$commentId}`).css("align-items", "unset");
        $(`#discussionBody${$commentId}`).removeClass("expanded");
        $(`#discussionImageBox${$commentId}`).css("display", "none");
      }
    });

    $(`#discussionImageLabel${$commentId}`).click(function () {
      $(`#discussionImage${$commentId}`).click();
      $(`#discussionImageLabel${$commentId}`).addClass("active");
    });

    $(`#discussionImage${$commentId}`).change(function () {
      $(`#discussionImageFileName${$commentId}`).text(
        $(`#discussionImage${$commentId}`)[0].files[0]?.name
      );
      $(`#discussionImageLabel${$commentId}`).removeClass("active");
    });
  });

  $("#discussionBody").on("input", function () {
    if ($(this).val().length > 0) {
      $("#discussionBox").css("height", "auto");
      $("#discussionBox").css("flex-direction", "column");
      $("#discussionBox").css("align-items", "end");
      $("#discussionBody").addClass("expanded");
      $("#discussionImageBox").css("display", "flex");
    } else {
      $("#discussionBox").css("height", "2.5rem");
      $("#discussionBox").css("flex-direction", "row");
      $("#discussionBox").css("align-items", "unset");
      $("#discussionBody").removeClass("expanded");
      $("#discussionImageBox").css("display", "none");
    }
  });

  $("#discussionImageLabel").click(function () {
    $("#discussionImage").click();
    $("#discussionImageLabel").addClass("active");
  });

  $("#discussionImage").change(function () {
    $("#discussionImageFileName").text($("#discussionImage")[0].files[0]?.name);
    $("#discussionImageLabel").removeClass("active");
  });

  $("#closeNewDiscussionSuccess").click(function () {
    $("#newDiscussionSuccess").css("display", "none");
  });

  $(".discussion-card .post-link").click(function (e) {
    e.stopPropagation();
  });

  // Scroll to selected comment
  const requestPath = window.location.pathname;
  if (
    requestPath.includes("/discussions/") ||
    requestPath.includes("/bills/")
  ) {
    const $postCard = document.getElementById(
      `${requestPath.replace(/\//g, "")}`
    );
    if ($postCard) {
      $postCard.scrollIntoView({ behavior: "smooth" });
    }
  }
});
