$(() => {
  $(".ask-btn").click(() => {
    $("#ask-a-question").focus();
  });

  $("#new_question").bind("ajax:complete", function() {
    $("#new_question")[0].reset();
    $("#success-submit-text").text($("#success-submit-text").text().replace("Thanks!", "Question added!"));
    $("#submission-success").addClass("d-flex").removeClass("d-none");
    $("#rep-no-questions").addClass("hide");
    setInterval(() => {
      $("#submission-success").fadeOut();
      $("#submission-success").removeClass("d-flex").addClass("d-none");
    }, 3000);
  });
});
