$(() => {
  var loadNextPage = function (currentTab) {
    var tabName = currentTab.slice(0, -4);
    var nextLink = $(`#${tabName} #next_link`);
    var next_url = nextLink.attr('href');
    var spinner = $(`#${tabName} .spinner-border`);

    if (nextLink.data("loading") || next_url === undefined) {
      return;
    } // prevent multiple loading

    nextLink.addClass('hide');
    spinner.addClass('show mt-2');

    nextLink.data("loading", true);

    $.ajax({
      url: next_url,
      data: { tab: tabName },
      dataType: "script",
      success: function() {
        spinner.removeClass('show mt-2').addClass('hide');
      },
      complete: function() {
        nextLink.data("loading", false);
      }
    });
  };

  window.addEventListener("scroll", () => {
    if ($(window).scrollTop() + $(window).height() >= $(document).height() - 20) {
      var currentTab = null;
      if (window.location.href.includes('officials')) {
        currentTab = $('ul#tabs-list .nav-link.active').attr('id');
      } else if (window.location.href.includes('search')) {
        currentTab = $('ul#feed-tabs .nav-link.active').attr('id');
      } else {
        currentTab = $('ul#feed-tabs .nav-link.active').attr('id');
      }
      loadNextPage(currentTab);
    }
  }, {
    passive: true, capture: true
  });
});
