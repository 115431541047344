$(() => {
  // Filters

  $(".news-feed-filter").change(() => {
    const filters = $(".news-feed-filter").map((i, el) => ({
      val: $(el).val(),
      name: $(el).data("filter-name"),
    }));
    window.location = urlWithUpdatedFilters(filters);
  });

  function urlWithUpdatedFilters(filters) {
    let params = "?";
    $.each(filters, (i, { name, val }) => {
      let param = "";
      if (!(i === 0)) {
        param += "&";
      }

      param += name;
      param += "=";
      param += val;

      params += param;
    });

    return window.location.href.split("?")[0] + params;
  }

  $("#feed-tabs a").on("click", function (e) {
    e.preventDefault();
    $(this).tab("show");
    $($(this).data('target')).addClass('show active');
  });
});
