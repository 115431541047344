$(function () {
  var $reportModal = $("#report-comment-modal");

  $(document).on("click", ".report-btn", function (e) {
    e.stopPropagation();
    var reportableId = $(this).data("reportable-id"),
      reportableType = $(this).data("reportable-type");
    createReport(reportableType, reportableId);
  });

  $(".cancel-report-btn").click(function (e) {
    e.stopPropagation();
    $reportModal.modal("hide");
  });

  function createReport(type, id) {
    $reportModal.modal("show");
    $reportModal.find("#report_reportable_id").val(id);
    $reportModal.find("#report_reportable_type").val(type);
    const elementName = type.split('::').pop();
    $reportModal.find("#modalLabel.modal-title").text(`Report ${elementName}`);
    $reportModal.find("#report-comment-help-text").text(`Provide some context to help us understand why you want to report this ${elementName.toLowerCase()}.`);
  }
});
