$(() => {
  $(document).on("click", ".upvote", function() {
    if (gon.role === "guest") {
      $("#registration-prompt-modal").modal("show");
    } else if (!gon.verified) {
      $("#verification-prompt-modal").modal("show");
    } else {
      (count = new Number($(this).attr("data-count"))),
        (type = $(this).data("type")),
        (id = $(this).data("id"));

      if ($(this).hasClass("upvoted")) {
        downvote($(this), count - 1, id, type);
      } else {
        upvote($(this), count + 1, id, type);
      }
    }
  });

  function upvote($tally, newCount, id, type) {
    $.ajax({
      url: "/upvotes",
      method: "POST",
      data: {
        upvote: {
          upvotable_id: id,
          upvotable_type: type,
        },
      },
      success(msg) {
        $tally.find(".count").html(newCount);
        $tally.attr("data-count", newCount);
        $tally.addClass("upvoted");
      },
    });
  }

  function downvote($tally, newCount, id, type) {
    $.ajax({
      url: `/upvotes/${id}`,
      method: "DELETE",
      data: {
        upvote: {
          upvotable_id: id,
          upvotable_type: type,
        },
      },
      success(msg) {
        $tally.find(".count").html(newCount);
        $tally.attr("data-count", newCount);
        $tally.removeClass("upvoted");
      },
    });
  }
});
